<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.00684 19.049H10.9498V13.799H6.74984L5.00684 19.049Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13.0498 13.799V19.049H18.9928L17.2498 13.799H13.0498Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M1.5 10.649V11.699V12.749H3.6V13.799H5.7V12.749H18.3L18.363 13.799H20.4V12.749H22.5V11.699V10.649H1.5Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.95 5H9.67948L8.14648 9.599H10.95V5Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14.3203 5H13.0498V9.599H15.8533L14.3203 5Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {}
</script>

<style></style>
