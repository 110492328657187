<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.9919 9.71787C18.2942 9.58916 16.9501 9.07192 15.9884 8.17524C14.4419 6.73262 14.2927 4.67705 14.2816 4.13541V4H12.3309V9.43078H14.2816V7.00918C14.5782 7.6075 14.9796 8.1478 15.4668 8.60444C15.9719 9.0731 16.5548 9.45006 17.1894 9.71835H6.8063C7.4409 9.45006 8.02382 9.0731 8.52884 8.60444C9.01608 8.1478 9.4175 7.6075 9.71405 7.00918V9.43078H11.6648V4H9.71787V4.13541C9.70687 4.67753 9.55758 6.7331 8.01112 8.17524C7.04985 9.07192 5.70579 9.58916 4.00766 9.71787H4V11.6701H20V9.71787H19.9919Z"
      fill="currentColor"
    />
    <path
      d="M4 14.2823H4.00766C5.70531 14.411 7.04985 14.9283 8.01112 15.8249C9.55758 17.2676 9.70687 19.3231 9.71787 19.8648V20.0002H11.6687V14.5694H9.71787V16.991C9.42133 16.3927 9.01991 15.8524 8.53267 15.3957C8.02765 14.9271 7.44473 14.5501 6.81013 14.2818H17.1932C16.5586 14.5501 15.9757 14.9271 15.4707 15.3957C14.9834 15.8524 14.582 16.3927 14.2855 16.991V14.5694H12.3309V20.0002H14.2816V19.8648C14.2931 19.3222 14.4419 17.2671 15.9884 15.8249C16.9501 14.9283 18.2942 14.411 19.9919 14.2823H19.9995V12.3311H4V14.2823Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {}
</script>

<style></style>
